<template>
	<div class="login-container">
		<div class="text_center">
			<p class="uptick-testnet-airdrop"> $UPTICK Airdrop Supplementary Campaign  Uptick Governance Supporters
			</p>
			<p class="connect-wallet" @click="connectBtn()"
				v-if="walletShow==1 && !this.$store.state.did && !this.mobileAddress">
				Connect Wallet </p>
			<div class="wallet-address" v-if="walletShow==2">
				<p v-if="this.mobileAddress"> Claim Wallet Address: {{this.mobileAddress}}</p>
				<p v-else> Claim Wallet Address: {{this.$store.state.did}}</p>
			</div>
			<div class="wallet-address mt-5" v-if="walletShow==2">
				<p> UPTICK Receiving Address: {{walletAddress}}</p>
			</div>
			<div class="ty-text" v-if="walletShow==2">
				Thank you for supporting the Uptick proposal in IRISnet Ecosystem
			</div>
			<div class="ver-layout" v-if="obj.activityStatus==2">
				<div class="unclaim-text" v-if="walletShow==2">
					Your Total Unclaimed: <span>{{ obj.unclaimedAmount!=null ? obj.unclaimedAmount:'0'}}</span> UPTICK
				</div>
				<div class="total-text" v-if="walletShow==2">
					You can receive: <span>{{ obj.currentAmount!=null ? obj.currentAmount:'0'}}</span> UPTICK
				</div>
				<div class="notice-text" v-if="walletShow==2">
				This phase is a two-week supplementary campaign for $UPTICK airdrop, providing an opportunity for the users to reclaim the unclaimed Uptick Airdrop. If you did not claim your airdrops within the designated time window, you can claim 50% of the unclaimed UPTICK during the campaign period. 
				</div>
				<div class="parse-text mt-5" v-if="walletShow==2">
					Supplementary Phase</div>
				<div class="time-text mt-1" v-if="walletShow==2">
					2023-07-12 07:00 - 2023-07-26 07:00 UTC+0
				</div>
				<div class="d-flex flex-lg-row flex-column">
					<div class="hor-layout" v-if="walletShow==2">
						<div v-if="obj.airdropLists!=null" style="width: 206px;">
							<p v-if="obj.status==0" class="btn_true" @click="btn_click(item)">Claim</p>
							<p v-else-if="obj.status==1" class="btn_false">Distributing</p>
							<p v-else-if="obj.status==2" class="btn_false">Received</p>
							<p v-else-if="obj.status==3" class="btn_false">Expired</p>
							<p v-else-if="obj.status==4" class="btn_false">Unstarted</p>
							<p v-else class="btn_true-gray">Claimed</p>
						</div>
					</div>
					<div class="hor-layout ml-5" style="margin-top: 20px;" v-if="walletShow==2 ">
						<div style="cursor: pointer;" @click="dialogdd()" v-if="obj.airdropLists!=null">
							<img src="@/assets/image/image_dd.png"
								style="width: 30px;height: 30px;margin-right: 20px;" />
						</div>
						<a :href="'https://explorer.uptick.network/uptick-network-mainnet/account/' + walletAddress" target="_blank">
							<img src="@/assets/image/eara.png" style="width: 30px;height: 30px;" />
						</a>
					</div>
				</div>

			</div>
			<p class="uptick-testnet-airdrop" style="margin-top: 80px;" v-if="obj.activityStatus==1">Unstarted</p>
			<p class="uptick-testnet-airdrop" style="margin-top: 80px;" v-if="obj.activityStatus==3">Ended</p>
			<loading :isShowLoading="isLoad"></loading>
			<div class="uptickList">
				<div class="uptickList_item" v-for="(item,index) in obj.airdropUptick" :key='index'>
					<p class="text_color">{{item.content}}</p>
					<div class="num_color">
						<span>{{item.airdropActivity!=null ? item.airdropActivity.amount :'0'}}</span>UPTICK
					</div>
				</div>
			</div>
		</div>
		<el-dialog append-to-body title="" :visible.sync="dialogVisible" width="50%" center style="top:120px">
			<p class="dialog_text_color">{{dialogText}}</p>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialog_btn()" class="btn_color">Submit</el-button>
			</span>
		</el-dialog>
		<el-dialog append-to-body title="" :visible.sync="dialogddVisible" center width="60%" style="top:100px">
			<!-- <div class="airdropLists_item" v-for="(item,index) in obj.airdropLists" :key='index'>
				<p class="text-size-12-bold">Phase #{{item.number}}</p>
				<p class="text-size-12" style="margin-left: 30px;">{{item.startTimeStr}} - {{item.endTimeStr}} UTC+0</p>
				<div v-if="item.status !=null" style="width: 100px;text-align: right;">
					<p v-if="item.status==0" class="text-size-12-bold">Claimable</p>
					<p v-else-if="item.status==1" class="text-size-12-distributing">Distributing</p>
					<p v-else-if="item.status==2" class="text-size-12-gray">Received</p>
					<p v-else-if="item.status==3" class="text-size-12-gray">Expired</p>
					<p v-else-if="item.status==4" class="text-size-12-gray">Unstarted</p>
				</div>
			</div> -->

			<div class="airdropLists_item" v-for="(item,index) in obj.airdropLists" :key='index'>
				<p class="text-size-12-bold" style="width:20%;text-align: center;" v-if="item.number != 11" >Phase #{{item.number}}</p>
					<p class="text-size-12-bold" style="width:20%;text-align: center;" v-else>Supplementary phase</p>
				<p class="text-size-12" style="width:60%;text-align: center;">{{item.startTimeStr}} - {{item.endTimeStr}} UTC+0</p>
				<div v-if="item.status !=null" style="width: 20%;text-align: right;">
					<p v-if="item.status==0" class="text-size-12-bold">Claimable</p>
					<p v-else-if="item.status==1" class="text-size-12-distributing">Distributing</p>
					<p v-else-if="item.status==2" class="text-size-12-gray">Received</p>
					<p v-else-if="item.status==3" class="text-size-12-gray">Expired</p>
					<p v-else-if="item.status==4" class="text-size-12-gray">Unstarted</p>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import api from "@/api";
	import Loading from "@/components/loading.vue";
	import {
		getAddress
	} from "../../../keplr/wallet";
	import {
		addUptickNet
	} from "../../../uptick/wallet";
	export default {
		components: {
			Loading
		},
		data() {
			return {
				itemSS: {},
				walletAddress: '',
				walletShow: 1,
				obj: {},
				dialogVisible: false,
				dialogddVisible: false,
				dialogText: '',
				isLoad: false,
				mobileAddress: '', // 接受移动端地址
				IRISAddress: ''
			};
		},
		
		mounted() {
			if (window.location.href.split('?')[1]) {
				this.mobileAddress = window.location.href.split('?')[1].split('=')[1];
				this.getUptickAddress();
			} else {
				addUptickNet();
			}
			window.eventBus.$on("isLoading", this.isLoading);
			this.initUser();

		},
		methods: {
			isLoading() {
				this.initUser();
			},
			async getUptickAddress() {
				let params = {
					authenticationAddress: this.mobileAddress,
					did: this.mobileAddress
				}
				let uptickData = await api.home.getUptickAddress(params);
				if (uptickData && uptickData.success) {
					this.walletShow = 2;
					this.walletAddress = uptickData.data
				}
			},
			async initUser() {
				this.isLoad = true
				const json = localStorage.getItem("key_user");
				debugger
				if (json) {
					// let param = {
					// 	address: this.$store.state.did
					// };
					// let myInfo = await api.home.createInfo(param);
					let accountInfo = await getAddress(0);
					// 钱包实时获取IRIS地址
					this.walletShow = 2;
					this.walletAddress = accountInfo.address
					//  + "\n" + accountInfo.address
					let res = await api.home.airdropList({
						address: this.$store.state.did,
						projectType: 4
					});
					this.obj = res.data;
					this.isLoad = false
				} else {

					// 适配移动端领取领取 
					if (this.mobileAddress) {
						this.getUptickAddress();
						let res = await api.home.airdropList({
							address: this.mobileAddress,
							projectType: 4
						});
						this.obj = res.data;
					}
					this.isLoad = false

				}
			},
			Open(e) {
				this.openWallet = e;
			},
			Status(s, account, type) {

				console.log(account);

			},
			connectBtn() {
				window.eventBus.$emit("isWallet");
			},
			btn_click(item) {
				this.dialogVisible = true
				this.dialogText = 'You are about to claim the ' + this.obj.currentAmount +
					' UPTICK, and the airdrop will take some time, please be patient.'
			},
			dialogdd() {
				if (this.obj.airdropLists == null) return;
				this.dialogddVisible = true
			},
			async dialog_btn() {
				this.dialogVisible = false
				let params = {}
				if (this.mobileAddress) {
					// 解决网络问题 导致切换钱包没有刷新页面 实时获取钱包地址
					let accountInfo = await getAddress(0);
					let irisInfo = await getAddress(1)
					this.IRISAddress = irisInfo.address
					this.walletAddress = accountInfo.address

					params = {
						address: this.walletAddress,
						iaaAddress: this.mobileAddress,
						airdropListId: this.obj.airdropListId,
						number: this.obj.currentNumber,
						mobileFrom: "mobile"
					}
				} else {
					// 解决网络问题 导致切换钱包没有刷新页面 实时获取钱包地址
					let accountInfo = await getAddress(0);
					let irisInfo = await getAddress(1)
					this.IRISAddress = irisInfo.address
					this.walletAddress = accountInfo.address

					params = {
						address: this.walletAddress,
						iaaAddress: this.IRISAddress,
						airdropListId: this.obj.airdropListId,
						number: this.obj.currentNumber
					}
				}

				let res = api.home.evmReceive(params);
				if (res) {
					this.initUser();
				}
			}
		},
	};
</script>

<style scoped>
	.text-size-12-bold {
		font-family: Helvetica-Bold;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #270645;

	}

	.text-size-12 {
		font-family: Helvetica;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #270645;
	}

	.text-size-12-gray {
		font-family: Helvetica-Bold;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #868686;
	}

	.text-size-12-distributing {
		font-family: Helvetica-Bold;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #33cc33;
	}

	.ver-layout {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.hor-layout {
		display: flex;
		justify-content: center;
		justify-items: center;
		align-items: center;
		margin-top: 30px;
	}

	.can-claim-text {
		text-align: center;
		font-family: Helvetica;
		font-size: 19px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 1px;
	}

	.can-claim-text span {
		font-family: Helvetica-Bold;
		font-size: 19px;
		font-weight: normal;
		letter-spacing: 1px;
		color: #7800f4;
	}

	.time-text {
		text-align: center;
		font-family: Helvetica;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #270645;
	}

	.parse-text {
		text-align: center;
		font-family: Helvetica;
		font-size: 15px;
		font-weight: bold;
		font-stretch: normal;
		letter-spacing: 1px;
	}

	.notice-text {
		text-align: center;
		font-family: Helvetica;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999999;
		position: absolute;
		width: 556px;
	}

	.unclaim-text{
			margin-top: 70px;
		text-align: center;
		font-family: Helvetica;
		font-size: 30px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 52px;
		letter-spacing: 1px;	
	}
	.unclaim-text span {
		font-family: Helvetica;
		font-size: 30px;
		font-weight: bold;
		letter-spacing: 1px;
		color: #c500ff;
	}

	.total-text {
	
		text-align: center;
		font-family: Helvetica;
		font-size: 30px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 40px;
		letter-spacing: 1px;
	}

	.total-text span {
		font-family: Helvetica;
		font-size: 30px;
		font-weight: bold;
		letter-spacing: 1px;
		color: #c500ff;
	}

	

	.ty-text {
		font-family: Helvetica;
		font-size: 15px;
		font-weight: normal;
		font-style: italic;
		font-stretch: normal;
		letter-spacing: 0px;
		text-align: center;
		margin-top: 20px;
	}

	.login-container {
		min-height: 100%;
		width: 100%;
		/* background: url("../../assets/image/image_kt.jpg") 0% 0% / 100% round rgb(240, 242, 245); */
		overflow: hidden;
		position: relative;
	}

	.text_center {
		margin: 10px auto;
		width: 100%;
	}

	.uptick-testnet-airdrop {
		font-family: Helvetica;
		font-size: 39px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 52px;
		letter-spacing: 1px;
		margin: 50px auto 35px;
		text-align: center;
	}

	.connect-wallet {
		width: 210px;
		height: 46px;
		background-color: #7800f4;
		border-radius: 25px;
		font-family: Helvetica;
		font-size: 19px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 46px;
		letter-spacing: 1px;
		color: #ffffff;
		text-align: center;
		margin: auto;
		cursor: pointer;
	}

	.wallet-address {
		background-color: rgba(240, 240, 240, .8);
		border-radius: 60px;
		/* opacity: 0.2; */
		height: 36px;
		width: 600px;
		margin: auto;
	}

	.wallet-address p {
		padding-top: 7px;
		height: 80px;
		white-space: pre-wrap;
		font-family: Helvetica;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 1px;
		text-align: center;
		color: #7800f4;
	}

	.uptickList {
		margin: 10px auto 50px;
		margin-left: 10%;
		margin-right: 10%;
	}

	.airdropLists_item {
		width: 100%;
		height: 30px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.uptickList_item {
		width: 100%;
		height: 70px;
		border-bottom: 1px solid #dcdfe6;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.uptickList_item .text_color {
		height: 80px;
		line-height: 80px;
		width: 75%;
		font-family: Helvetica;
		font-size: 15px;
		font-weight: bold;
		font-stretch: normal;
		letter-spacing: 1px;
	}

	.dialog_text_color {
		word-break: normal;
		text-align: center;
		font-family: Helvetica;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		/* line-height: 50px; */
		letter-spacing: 0px;
		color: #270645;
	}

	.num_color {
		height: 80px;
		line-height: 80px;
		text-align: right;
		width: 25%;
		font-family: Helvetica;
		font-size: 15px;
		font-weight: normal;
		letter-spacing: 1px;
	}

	.num_color span {
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 52px;
		letter-spacing: 1px;
		color: #7800f4;
		margin-right: 5px;
	}

	.btn_true {
		width: 206px;
		height: 46px;
		background-color: #270645;
		border-radius: 25px;
		font-family: Helvetica;
		font-size: 23px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 46px;
		letter-spacing: 1px;
		color: #ffffff;
		align-self: center;
		text-align: center;
		/* margin:20px auto; */
		cursor: pointer;
	}

	.btn_true-gray {
		width: 206px;
		height: 46px;
		background-color: #666666;
		border-radius: 25px;
		font-family: Helvetica;
		font-size: 23px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 46px;
		letter-spacing: 1px;
		color: #ffffff;
		align-self: center;
		text-align: center;
		/* margin:20px auto; */
		cursor: pointer;
	}


	.btn_false {
		margin-top: 25px;
		width: 206px;
		height: 46px;
		font-family: Helvetica;
		background-color: transparent;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 46px;
		letter-spacing: 1px;
		align-self: center;
		text-align: center;
		margin: 20px auto;
		cursor: pointer;
	}

	.btn_color {
		width: 300px;
		height: 50px;
		line-height: 11px;
		text-align: center;
		background-color: #270645;
		border-radius: 25px;
		font-family: Helvetica;
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		color: #ffffff;
	}

	@media screen and (max-width:600px) {
		.notice-text {
			width: 450px;
			text-align: center;
			font-family: Helvetica;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #999999;
			position: absolute;
		}

		.wallet-address {
			background-color: rgba(240, 240, 240, .8);
			border-radius: 36px;
			/* opacity: 0.2; */
			width: 100%;
			height: 50px;
			padding-bottom: 10px;
		}

		.wallet-address p {
			padding: 10px;
			font-family: Helvetica;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 1px;
			line-height: normal;
			text-align: center;
		}
		.uptickList_item .text_color{
			font-size: 12px;
			line-height: 20px;
			width: 72%;
		}
	}
</style>
