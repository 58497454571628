
export async function addUptickNet() {
    debugger
    if (!window.getOfflineSigner || !window.keplr) {
        alert("Please install keplr extension");
    } else {
        if (window.keplr.experimentalSuggestChain) {
            try {
                // Keplr v0.6.4 introduces an experimental feature that supports the feature to suggests the chain from a webpage.
                // cosmoshub-3 is integrated to Keplr so the code should return without errors.
                // The code below is not needed for cosmoshub-3, but may be helpful if you’re adding a custom chain.
                // If the user approves, the chain will be added to the user's Keplr extension.
                // If the user rejects it or the suggested chain information doesn't include the required fields, it will throw an error.
                // If the same chain id is already registered, it will resolve and not require the user interactions.
                await window.keplr.experimentalSuggestChain({
                    // Chain-id of the Osmosis chain.
                    chainId: process.env.VUE_APP_UPTICK_CHAIN_ID,
                    // # UPTICK环境
        

                    // The name of the chain to be displayed to the user.
                    chainName: "Uptick Network",
                    // RPC endpoint of the chain. In this case we are using blockapsis, as it's accepts connections from any host currently. No Cors limitations.
                    rpc: process.env.VUE_APP_UPTICK_RPC,
                    // REST endpoint of the chain.
                    rest: process.env.VUE_APP_UPTICK_REST2,
                    // Staking coin information
                    stakeCurrency: {
                        coinDenom: "uptick",
                        coinMinimalDenom: "auptick",
                        coinDecimals: 18,

                    },

                    bip44: {
                    
                        coinType: 60,
                    },
                   
                    bech32Config: {
                        bech32PrefixAccAddr: "uptick",
                        bech32PrefixAccPub: "uptickpub",
                        bech32PrefixValAddr: "uptickvaloper",
                        bech32PrefixValPub: "uptickvaloperpub",
                        bech32PrefixConsAddr: "uptickvalcons",
                        bech32PrefixConsPub: "uptickvalconspub",
                    },
                    currencies: [
                        {
                            coinDenom: "UPTICK",
                            coinMinimalDenom: "auptick",
                            coinDecimals: 18,
                        },
                    ],
                    // List of coin/tokens used as a fee token in this chain.
                    feeCurrencies: [
                        {
                            coinDenom: "UPTICK",
                            coinMinimalDenom: "auptick",
                            coinDecimals: 18,
                        },
                    ],
                  
                    coinType: 60,
                   
                    gasPriceStep: {
                      low: 1 * 100000000000,
                      average: 2 * 100000000000,
                      high: 4 * 100000000000,
                    },
					
			
							  
					features:['ibc-transfer','ibc-go', 'eth-address-gen', 'eth-key-sign']
                });

                // location.reload();
            } catch {
                alert("Failed to suggest the chain");
                // location.reload();
            }
        } else {
            alert("Please use the recent version of keplr extension");
        }
    }
}

